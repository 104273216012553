
/**
 * Объект-конфигурация компонентов текстового поля ввода по типам
 */
const types = {
  default: 'CoreInputTextTypesDefault',
  number: 'CoreInputTextTypesNumber',
  text: 'CoreInputTextTypesDefault',
  date: 'CoreInputTextTypesDate',
  'date-time': 'CoreInputTextTypesDateTime',
  phone: 'CoreInputTextTypesPhone',
  email: 'CoreInputTextTypesEmail',
  url: 'CoreInputTextTypesUrl',
  inn: 'CoreInputTextTypesInn',
  password: 'CoreInputTextTypesPassword',
  username: 'CoreInputTextTypesUsername',
}
export default {
  name: 'CoreInputText',

  /**
   * Нужно для проброса атрибутов дочерним компонентам
   */
  inheritAttrs: false,

  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    /**
     * Двунаправленная привязка текста введённого в поле
     */
    value: {
      type: [String, Number, Date],
      default: '',
    },

    /**
     * Тип поля ввода.
     */
    type: {
      type: String,
      default: 'default',

      validator: (value) => {
        return Object.keys(types).includes(value)
      },
    },
  },

  computed: {
    inputForType() {
      return this.getType(this.type)
    },
  },
  methods: {
    getType: (type) => types[type] || types.default,
  },
}
