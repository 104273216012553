
import ogPagesMixins from '@/mixins/ogPagesMixins'
export default {
  mixins: [ogPagesMixins],
  layout: 'clear',
  data() {
    return {
      seoTitle: 'Авторизация | Мегасреда',
      seoDescription:
        'Авторизация на платформе Мегасреда позволит вам использовать все возможности, без ограничений',
      login: {
        username: null,
        password: null,
        loginFrom: null,
      },
      recoveryEmail: null,
      error: null,
      loginError: null,
      passwordError: null,
      isEmailValid: false,
      isPasswordValid: false,
      userData: null,
    }
  },
  computed: {
    robotsMeta() {
      return [
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ]
    },
    isValid() {
      return this.validate()
    },
    isAdmin() {
      return !!this.$auth?.user?.roles?.some((role) => ['ADMIN'].includes(role))
    },
  },
  mounted() {
    // initialize Google Sign in
    google.accounts.id.initialize({
      client_id:
        '351992118372-h98mrdko377bpn8c6kpl6d3akp17u1jc.apps.googleusercontent.com',
      callback: this.handleCredentialResponse,
      context: 'signin',
    })

    // render button
    google.accounts.id.renderButton(document.getElementById('googleButton'), {
      type: 'standard',
      size: 'large',
      text: 'signin_with',
      shape: 'rectangular',
      logo_alignment: 'left',
      width: 220,
    })
  },
  methods: {
    async handleCredentialResponse(response) {
      const userData = await this.$axios.$get('/auth/google/gsi', {
        params: {
          credential: response.credential,
          registerFrom: this.$cookies.get('welcomeReferral'),
        },
      })

      if (userData) {
        this.$auth.setUserToken(userData.accessToken).then(() => {
          this.$auth.setUser(userData.user)
          if (userData.isNewUser) {
            // Для шагов после регистрации
            this.$cookies.set('newUser', true, {
              path: '/',
              maxAge: 60 * 60 * 24 * 30 * 12,
            })
          }
          if (this.$route?.params?.from) {
            this.$router.push(this.$route.params.from)
          } else {
            this.$router.push('/')
          }
        })
      }
    },
    loginWithYandex() {
      const API_URL = 'https://api.megasreda.ru'

      try {
        window.open(
          `${API_URL}/auth/yandex`,
          'Auth', // window.name - если позднее вызвать window.open() с тем же именем, то браузеры (кроме IE) заменяют существующее окно на новое.
          'width=500,height=650,status=yes,toolbar=no,menubar=no,location=no, top=' +
            (screen.height - 500) / 2 +
            ',left=' +
            (screen.width - 800) / 2 +
            ',' // window.params
        )
        if (typeof window !== 'undefined') {
          window.addEventListener('message', (e) => {
            if (e.origin !== API_URL) return
            const userData = JSON.parse(e.data)

            if (userData) {
              this.$auth.setUserToken(userData.accessToken).then(() => {
                this.$auth.setUser(userData.user)
                if (userData.isNewUser) {
                  // Для шагов после регистрации
                  this.$cookies.set('newUser', true, {
                    path: '/',
                    maxAge: 60 * 60 * 24 * 30 * 12,
                  })
                }
                if (this.$route?.params?.from) {
                  this.$router.push(this.$route.params.from)
                } else {
                  this.$router.push('/')
                }
              })
            } else {
              console.log('access token или yandexId отсутствуют')
            }
          })
        }
      } catch (error) {
        console.log('onClickAuth', error.message)
      }
    },
    loginWithVk() {
      const API_URL = 'https://api.megasreda.ru'

      try {
        window.open(
          `${API_URL}/auth/vk`,
          'Auth', // window.name - если позднее вызвать window.open() с тем же именем, то браузеры (кроме IE) заменяют существующее окно на новое.
          'width=700,height=400,status=yes,toolbar=no,menubar=no,location=no, top=' +
            (screen.height - 500) / 2 +
            ',left=' +
            (screen.width - 800) / 2 +
            ',' // window.params
        )
        if (typeof window !== 'undefined') {
          window.addEventListener('message', (e) => {
            if (e.origin !== API_URL) return
            const userData = JSON.parse(e.data)

            if (userData) {
              this.$auth.setUserToken(userData.accessToken).then(() => {
                this.$auth.setUser(userData.user)
                if (userData.isNewUser) {
                  // Для шагов после регистрации
                  this.$cookies.set('newUser', true, {
                    path: '/',
                    maxAge: 60 * 60 * 24 * 30 * 12,
                  })
                }
                if (this.$route?.params?.from) {
                  this.$router.push(this.$route.params.from)
                } else {
                  this.$router.push('/')
                }
              })
            } else {
              console.log('access token или vkId отсутствуют')
            }
          })
        }
      } catch (error) {
        console.log('onClickAuth', error.message)
      }
    },
    validate() {
      return this.isEmailValid && this.isPasswordValid
    },
    async userLogin() {
      this.error = null
      this.loginError = null
      this.passwordError = null
      if (this.validate()) {
        try {
          await this.$auth.loginWith('local', {
            data: {
              ...this.login,
              loginFrom: this.$cookies.get('welcomeReferral'),
            },
          })

          if (this.$route?.params?.from) {
            this.$router.push(this.$route.params.from)
          } else {
            this.$router.push('/')
          }
        } catch (err) {
          if (err.response?.data?.statusCode) {
            switch (err.response.data.statusCode) {
              // Пустой email или пароль
              case 400:
                this.loginError = {
                  status: 'error',
                  message: err.response.data.message,
                }
                break

              // Пользователь забанен
              case 401:
                this.error = err.response.data.message
                break

              // Неверный пароль
              case 402:
                this.passwordError = {
                  status: 'error',
                  message: err.response.data.message,
                }
                break

              // Нет пользователя в базе с такими данными
              case 404:
                this.loginError = {
                  status: 'error',
                  message: err.response.data.message,
                }
                break

              // Если вдруг нераспознанная ошибка на сервере
              default:
                this.error = 'Что-то пошло не так...'
                break
            }
          }
        }
      }
    },
  },
}
